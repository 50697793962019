@import url('https://fonts.googleapis.com/css?family=BioRhyme:100,300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,600,700,800,900');

body {
  margin: 0;
  font-family: 'BioRhyme', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html, #root{
  margin: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#appContent{
  margin: 0;
  /*background-image: linear-gradient(#1DE9B6, #0097A7);*/
  height: 100%;
  width: 100%;
}

.menu-list{
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 2.5rem;
}

.menuItem{
  
  text-align: center;
  cursor: pointer;
}

#videoWrapper {
  background: white;
  width: 16.6vw;
  aspect-ratio: 1;
  border-radius: 8.3vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /*cursor: pointer;*/
}

.title{
  font-size: 3.5em;
  font-weight: bolder;
}

h3{
  font-size: 2em;
  font-weight: bold;
}

#menuCER{
  color: white;
  font-size: 1.1em;
  font-weight: 500;
  list-style: none;
  text-align: center;
  margin: 0px 0px 20px ;
  padding: 0px;
}

#menuCER li{
  margin: 5px 0px;
}

.step-explanation{
  color: #56789C;
  font-weight: 500;
}

.step-span{
  background-color: #2C5683;
  color: white;
  font-size: 1.25rem;
  font-weight: 700;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  margin-right: 0.5rem;
}

.bold {
  font-weight: 700;
}

.flex {
  display: flex;
}

.flex-row{
  flex-direction: row;
}

.flex-column{
  flex-direction: column;
}

.jcc{
  justify-content: center;
}

.aic{
  align-items: center;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.primary-color{
  color: #0097A7;
}

.blue-light-color{
  color: #2C5683;
}

.big-title{
  font-size: 50px;
}